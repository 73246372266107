<template>
<div v-if="order">
  <AppBar :title="$t('orderNo.title') + ' ' + order.businessId"/>
  <v-progress-linear v-if="loading" color="blue" class="progress" indeterminate></v-progress-linear>
  <v-card flat v-if="order" class="rounded-xl pt-2 pb-4">
    <v-card-title>
      <v-chip label class="ml-4" :color="order.state == $t('cashierOrder.paid') ? 'green' : order.state == $t('cashierOrder.cancelled') ? 'red' : ''">{{ order.state }}</v-chip>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
        <v-row class="mt-8">
          <v-col cols="3">
            <h4>{{ $t('cashierOrder.orderDate') }}</h4>
            <p>{{ order.created | dateFormat("DD.MM.YYYY HH:mm") }}</p>
            <h4>{{ $t('cashierOrder.fulfillmentDate') }}</h4>
            <p><span v-if="order.completionDate">{{ order.completionDate | dateFormat("DD.MM.YYYY HH:mm") }}</span><span v-else>-</span></p>
          </v-col>
          <v-col cols="2">
            <h4>{{ $t('cashierOrder.orderNumber') }}</h4>
            <p>{{ order.businessId }}</p>
              <h4>{{ $t('cashierOrder.orderSum') }}</h4>
            <p>{{ order.totalChargeAmount.toFixed(2) }} {{ getLocalCurrency }}</p>
          </v-col>
          <v-col cols="2">
            <h4>{{ $t('cashierOrder.paymentMethod') }}</h4>
            <p>{{ order.paymentMethod }}</p>
            <h4>{{ $t('cashierOrder.deliveryMethod') }}</h4>
            <p>{{ order.type }}</p>
          </v-col>
          <v-col cols='1'></v-col>
          <v-col cols="4" v-if="order.allowedActions">
          <h5 v-if='isAnyAllowedAction' class='mt-n4'>{{ $t('cashierOrder.statusManage') }}</h5>
            <v-btn v-if="order.allowedActions.assignCardTokens && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole)" @click="assignTokens" :disabled="loading" class="my-2" color="primary" depressed block rounded>{{ $t('cashierOrder.assignTokens') }}</v-btn>
            <v-btn v-if="order.allowedActions.complete && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole || userRoleId === cashierRole)" @click="complete" :disabled="loading" class="my-2" color="success" depressed block rounded>{{ $t('cashierOrder.orderFulfilled') }}</v-btn>
            <v-badge v-if="order.allowedActions.resendPickUpCode && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole || userRoleId === cashierRole)" style='min-width: 100%; margin-top: 10px; margin-left: -10px' color='primary' :content='sentPickUpCodeCount'><v-btn  @click="resendPickUpCode" :disabled="loading" class="my-2" style='top: -10px; left: 10px' depressed block rounded>{{ $t('cashierOrder.resendPickupCode') }}</v-btn></v-badge>
            <h6 v-if="order.allowedActions.resendPickUpCode && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole || userRoleId === cashierRole)" class="mt-n3 mb-1 text-center" style="width:250px; margin: auto auto -25px;">{{ $t('cashierOrder.lastTimePickupCode') }} {{lastPickUpCodeSentDate | dateFormat("DD.MM.YYYY HH:mm")}}</h6>
            <v-text-field v-if="order.allowedActions.resendPickUpCode && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole || userRoleId === cashierRole)" v-model="pickupCode" :label="$t('pickupCompleted.label')" class="mt-6" style="width:250px; margin: auto auto -25px;" @input="pickUpCodeToUpperCase"></v-text-field>
            <v-btn v-if="order.allowedActions.resendPickUpCode && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole || userRoleId === cashierRole)" @click="markAsPickedUp" :disabled="loading" class="my-2" color='success' depressed block rounded>{{ $t('cashierOrder.orderReceived') }} </v-btn>
            <v-btn v-if="order.allowedActions.resendDetailsForCashier && !order.allowedActions.resendPickUpCode && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === cashierRole)"  @click="markAsReadyForPickUp" :disabled="loading" class="my-2" color="secondary-darken1" depressed block rounded>{{ $t('cashierOrder.readyForPickup') }}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-card flat outlined>
              <v-card-title>
                {{ $t('cashierOrder.order') }}
              </v-card-title>
              <v-card-text>
                <v-simple-table fixed-header height="500px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">{{ $t('cashierOrder.name') }}</th>
                        <th class="text-left">{{ $t('cashierOrder.count') }}</th>
                        <th class="text-right">{{ $t('cashierOrder.sum') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(card, i) in cards" :key="i">
                        <td v-if='order.giftCards[0].shoppingCenterName !== null'>{{ $t('cashierOrder.giftcard') }} {{ order.giftCards[0].shoppingCenterName }}</td>
                        <td v-else>{{ $t('cashierOrder.giftcard') }}</td>
                        <td>{{ card.count }} x {{ card.price.toFixed(2) }} {{ getLocalCurrency }}</td>
                        <td class="text-right">{{ (card.count * card.price).toFixed(2) }} {{ getLocalCurrency }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="ma-4 ml-auto" style="width: 350px;">
                  <div class="d-flex justify-space-between my-1">
                    <div>{{ $t('cashierOrder.total') }}</div>
                    <div class="font-weight-black">{{ order.totalChargeAmount.toFixed(2) }} {{ getLocalCurrency }}</div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" v-if="order.receiver">
            <v-card flat>
              <v-card-title>
                {{  $t('customerDetails.title') }}
              </v-card-title>
              <v-card-text>
                <h4>{{  $t('customerDetails.FirstAndLastName') }}</h4>
                <p>{{ order.receiver.name }} {{ order.receiver.surname }}</p>
                <h4>{{  $t('customerDetails.Email') }}</h4>
                <p>{{ order.receiver.email }}</p>
                <h4>{{  $t('customerDetails.Phone') }}</h4>
                <p v-if="order.receiver.phone && order.receiver.phone.countryCallingCode !== ''">+{{ order.receiver.phone.countryCallingCode}} {{ order.receiver.phone.number }}</p>
                <p v-if="order.receiver.phone.countryCallingCode === ''">{{ order.receiver.phone.countryCallingCode}} {{ order.receiver.phone.number }}</p>
                <h4>{{  $t('customerDetails.Address') }}</h4>
                <p v-if='order.receiver.street !== ""'>{{ order.receiver.street }}, {{ order.receiver.zipCode }} {{ order.receiver.city }}</p>
                <p v-if='order.receiver.street === ""'>{{ order.receiver.street }} {{ order.receiver.zipCode }} {{ order.receiver.city }}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card flat>
              <v-card-title>
                {{  $t('invoiceDetails.title') }}
              </v-card-title>
              <v-card-text>
                <h4>{{  $t('invoiceDetails.Name') }}</h4>
                <p>{{ order.accountingNote.name }}</p>
                <h4>{{  $t('invoiceDetails.VATNumber') }}</h4>
                <p>{{ order.accountingNote.vatNumber }}</p>
                <h4>{{  $t('invoiceDetails.Address') }}</h4>
                <p v-if='order.accountingNote.street !== ""'>{{ order.accountingNote.street }} {{ order.accountingNote.zipCode }}, {{ order.accountingNote.city }}</p>
                <p v-if='order.accountingNote.street === ""'>{{ order.accountingNote.street }} {{ order.accountingNote.zipCode }} {{ order.accountingNote.city }}</p>
                <h4>{{  $t('invoiceDetails.AccountingNoteNumber') }}</h4>
                <p>{{ order.accountingNoteNumber }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-8" v-if="order.logs.length > 0">
          <v-col cols="8">
            <v-card flat>
              <v-card-title>{{  $t('changeHistory.title') }}</v-card-title>
              <v-card-text>
                <v-simple-table class="mt-2">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th style="width: 150px" class="text-center">{{  $t('changeHistory.date') }}</th>
                        <th style="width: 200px" class="text-center">{{  $t('changeHistory.user') }}</th>
                        <th class="text-left">{{  $t('changeHistory.name') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(log, i) in order.logs" :key="i">
                        <td class="text-center">{{ log.date | dateFormat("DD.MM.YYYY HH:mm") }}</td>
                        <td class="text-center">{{ log.actionPerformer }}</td>
                        <td>{{ log.actionName }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
    </v-card-text>
  </v-card>
  <AssignCardTokens ref="assignTokens" @refresh="getOrder"/>
  <Confirm ref="confirm"></Confirm>
  </div>
</template>
<script>
import { adminRoleId, managerRoleId, employeeRoleId, cashierRoleId, globalAdminId } from '@/utils/helpers'
import Service from '@/services'
import AssignCardTokens from './Dialogs/AssignCardTokens.vue'
import Confirm from '../../components/dialogs/Confirm.vue'
export default {
  components: { AssignCardTokens, Confirm },
  name: 'CashierOrder',
  data () {
    return {
      cards: null,
      totalCards: 0,
      order: null,
      pickupCode: null,
      loading: false
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.getOrder()
    }
  },
  computed: {
    sentPickUpCodeCount () {
      return this.order.logs.filter(log => log.actionName === "Resend order's pickup code").length + 1
    },
    lastPickUpCodeSentDate () {
      const logs = this.order.logs
        .filter(log => log.actionName === "Resend order's pickup code")
        .map(log => new Date(log.date))
      if (logs.length < 1) {
        const lastDateArr = this.order.logs
          .filter(log => log.actionName === 'Mark as ready for pick up')
          .map(log => new Date(log.date))
        return lastDateArr.pop().toISOString()
      }
      return logs.pop().toISOString()
    },
    isAnyAllowedAction () {
      return Object.values(this.order.allowedActions).some(a => a === true)
    },
    userRoleId () {
      return this.$store.getters.userRoleId
    },
    adminRole () {
      return adminRoleId
    },
    globalAdminRole () {
      return globalAdminId
    },
    employeeRole () {
      return employeeRoleId
    },
    managerRole () {
      return managerRoleId
    },
    cashierRole () {
      return cashierRoleId
    },
    getLocalCurrency () {
      return localStorage.getItem('currency')
    }
  },
  methods: {
    calculateCards () {
      const cardCounts = {}
      this.order.giftCards.forEach(card => {
        const price = parseFloat(card.chargeAmount.toFixed(2))
        cardCounts[price] = (cardCounts[price] || 0) + 1
      })

      const result = Object.entries(cardCounts).map(([price, count]) => ({
        count,
        price: parseFloat(price)
      }))

      const totalCards = result.reduce((sum, card) => sum + card.count, 0)
      this.cards = result
      this.totalCards = totalCards
    },
    pickUpCodeToUpperCase () {
      this.pickupCode = this.pickupCode.toUpperCase()
    },
    async getOrder () {
      this.loading = true

      try {
        const { data } = await Service.get(`/Cashier/Order/${this.$route.params.id}`)
        this.order = data
        this.calculateCards()
      } catch (error) {}

      this.loading = false
    },
    assignTokens () {
      this.$refs.assignTokens.show(this.order.giftCards, this.order.id)
    },
    async complete () {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('orderModals.close'), this.$t('orderModals.complete'))) {
        try {
          const { data } = await Service.post(`/Cashier/Complete/${this.$route.params.id}`)
          this.order = data
        } catch (error) {}
      }

      this.loading = false
    },
    async markAsReadyForPickUp () {
      this.loading = true
      if (await this.$refs.confirm.open(this.$t('orderModals.readyForPickup'), this.$t('orderModals.markAsReadyForPickup'))) {
        try {
          const { data } = await Service.post(`Cashier/MarkAsReadyForPickUp/${this.$route.params.id}`)
          this.order = data
        } catch (error) {}
      }

      this.loading = false
    },
    async markAsPickedUp () {
      this.loading = true
      if (await this.$refs.confirm.open(this.$t('orderModals.orderPickup'), this.$t('orderModals.confirmPickupOrder'))) {
        try {
          this.order = await Service.post(`Cashier/MarkAsPickedUp/${this.$route.params.id}`, { pickUpCode: this.pickupCode })
            .then(async () => {
              const { data } = await Service.post(`/Cashier/Complete/${this.$route.params.id}`)
              return data
            })
        } catch (error) {}
      }

      this.loading = false
    },
    async resendPickUpCode () {
      this.loading = true
      if (await this.$refs.confirm.open(this.$t('orderModals.sendPickupCode'), this.$t('orderModals.resendPickupCode'))) {
        try {
          const { data } = await Service.post(`/Cashier/ResendPickUpCode/${this.$route.params.id}`)
          this.order = data
        } catch (error) {}
      }

      this.loading = false
    }
  }
}
</script>
